import Webcam from "react-webcam";
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import headers from "@/app/helpers/default_headers.ts"
import { profilePicAtom, editProfilePicStateAtom } from "@/app/state";
import { useAtom, atom } from "jotai"
import { useState, useRef } from "react";

import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";


const picAtom = atom(undefined)

function Cam() {
  // eslint-disable-next-line
  const [editProfilePicState, setEditProfilePicState] = useAtom(editProfilePicStateAtom)

  // eslint-disable-next-line
  const [pic, setPic] = useAtom(picAtom)

  const videoConstraints = {
    // width: 1920,
    // height: 1920,
    facingMode: "user"
  };


  return (<Dialog open={true} onClose={console.log} className="relative z-10">
    <DialogBackdrop
      transition
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
    />

    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex client-p-well-data min-h-full justify-center text-center sm:items-center sm:p-0">
        <DialogPanel
          transition
          className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-5/6 sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          style={{ padding: '0', margin: '0', width: 'auto' }}
        >
          <div className="flex justify-center">
            <div className="" style={{}}>
              <Webcam
                audio={false}
                height={720}
                screenshotFormat="image/jpeg"
                width={1280}
                videoConstraints={videoConstraints}
              >
                {({ getScreenshot }) => (
                  <button
                    style={{ backgroundColor: 'red', position: 'absolute', right: '0', top: '15px' }}
                    className="text-sm mr-4 font-semibold text-gray-900 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      const imageSrc = getScreenshot()
                      // setPicFromCam(() => imageSrc)
                      setPic(imageSrc)
                      setEditProfilePicState(2)
                      // close(imageSrc)

                    }}
                  >
                    Capture
                  </button>
                )}
              </Webcam>
            </div>
          </div>

          <div>
            <button
              type="button"
              style={{ backgroundColor: 'grey', position: 'absolute', right: '90px', top: '15px' }}
              className="text-sm mr-4 font-semibold text-gray-900 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setEditProfilePicState(0)}
            >
              Cancel
            </button>
          </div>

        </DialogPanel>
      </div>
    </div>
  </Dialog>)

}


function ImageEditor() {

  // eslint-disable-next-line
  const [editProfilePicState, setEditProfilePicState] = useAtom(editProfilePicStateAtom)
  // eslint-disable-next-line
  const [pic, setPic] = useAtom(picAtom)

  const cropperRef = useRef<ReactCropperElement>(null);
  // eslint-disable-next-line
  const [profilePic, setProfilePic] = useAtom(profilePicAtom)

  return (<Dialog open={true} onClose={console.log} className="relative z-10">
    <DialogBackdrop
      transition
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
    />

    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex client-p-well-data min-h-full items-end justify-center text-center sm:items-center sm:p-0">
        <DialogPanel
          transition
          className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-5/6 sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          style={{ padding: '0', margin: '0', width: 'auto' }}
        >

          <div>


            <button
              type="button"
              style={{ backgroundColor: 'red', position: 'absolute', right: '20px', top: '15px' }}
              className="text-sm mr-4 font-semibold text-gray-900 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                const cropper = cropperRef.current?.cropper;
                if (cropper) {

                  const img = cropper.getCroppedCanvas().toDataURL()
                  // setPic(cropper.getCroppedCanvas().toDataURL())

                  fetch('/profile/edit_pic.json', {
                    method: 'post',
                    headers,
                    body: JSON.stringify({
                      image: img
                    })
                  })
                    .then(() => {
                      setProfilePic(img)
                      setEditProfilePicState(0)
                    })

                }
              }}
            >
              Save
            </button>

            <button
              type="button"
              style={{ backgroundColor: 'grey', position: 'absolute', right: '90px', top: '15px' }}
              className="text-sm mr-4 font-semibold text-gray-900 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setEditProfilePicState(0)}
            >
              Cancel
            </button>
          </div>


          <div className="flex justify-center mt-24">
            <div className="" style={{ minWidth: '500px', minHeight: '500px' }}>


              <Cropper
                src={pic}
                style={{}}
                cropBoxMovable={true}
                cropBoxResizable={true}
                guides={false}
                scalable={false}
                crop={console.log}
                ref={cropperRef}
              />
            </div>
          </div>



        </DialogPanel>
      </div>
    </div>
  </Dialog>)
}


export default function EditProfilePic() {
  // eslint-disable-next-line
  const [profilePic, setProfilePic] = useAtom(profilePicAtom)

  const [pic] = useAtom(picAtom)
  // eslint-disable-next-line
  const [picFromCam, setPicFromCam] = useState(null)

  // eslint-disable-next-line
  const [editProfilePicState, setEditProfilePicState] = useAtom(editProfilePicStateAtom)


  console.log('pic ', pic)
  console.log('editProfilePicState ', editProfilePicState)
  return (
    <div>

      {editProfilePicState === 1 ? <Cam /> : null}
      {editProfilePicState === 2 ? <ImageEditor /> : null}


    </div>

  )
}