import { useLayoutEffect } from "react";
import headers from "@/app/helpers/default_headers.ts"

export default function useGlobalClickHandler(fpPromise) {

  const handleClick = async (ev) => {
    const email = localStorage.getItem('email') || ''
    const fp = await fpPromise
    const result = await fp.get()
    
    fetch('/ev', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        track: {
          client_time: new Date().toISOString(),
          event_type: 'click',
          email,
          fp: result.visitorId,
          properties: {
            target: ev.target.outerHTML,
            path: location.pathname
          }    
        }
      })
    })
  }

  useLayoutEffect(() => {
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, []);
}