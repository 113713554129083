"use client"

import Head from 'next/head'
import { ErrorBoundary } from "react-error-boundary"
import { highlightEnabledAtom } from '@/app/state';
import { useAtom } from 'jotai';


import headers from "@/app/helpers/default_headers"


import localFont from "next/font/local";
import "./globals.css";

// import Image from "next/image";
// import logo from "./images/evans-energy-logo.png"
// import Link from "next/link";


// eslint-disable-next-line





import { HighlightInit } from '@highlight-run/next/client'



const geistSans = localFont({
  src: "./fonts/GeistVF.woff",
  variable: "--font-geist-sans",
  weight: "100 900",
});
const geistMono = localFont({
  src: "./fonts/GeistMonoVF.woff",
  variable: "--font-geist-mono",
  weight: "100 900",
});




export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {

  const [highlightEnabled] = useAtom(highlightEnabledAtom)



  // eslint-disable-next-line
  function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.



    if (H) {
      H.track("Error saving well data", {
        error: error.message,
        trace: error.stack,
      });
    }

    fetch('/ev', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        track: {
          client_time: new Date().toISOString(),
          event_type: 'client_error',
          // fp: result.visitorId,
          properties: {
            path: location.pathname,
            error: error.message,
            trace: error.stack,
          }
        }
      })
    })
    // resetErrorBoundary()


    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }



  return (
    <>
      {highlightEnabled ? (
        <HighlightInit
          projectId={'qe981lod'}
          serviceName="ee-portal"
          tracingOrigins
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: ['/api/ping', '/_next', '/accounts/sign_in', '/accounts/password'],
          }}
        />) : null}
      <html lang="en">
        <Head>
          <title>Evans Energy II - Joint Venture Oil & Gas</title>
          <link rel="manifest" href="/manifest.json" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        </Head>
        <body
          className={`${geistSans.variable} ${geistMono.variable} antialiased`}
        >

          <ErrorBoundary fallbackRender={fallbackRender} >
            {children}
          </ErrorBoundary>
        </body >
      </html >
    </>
  )
}