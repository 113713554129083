"use client"

import { useEffect, useState } from "react"
// import Link from 'next/link'
import isBrowser from '../helpers/is_browser'
import { pageDataAtom, wsAllowedAtom } from "../state"
import { useAtom } from "jotai"
import headers from "@/app/helpers/default_headers.ts"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import useGlobalClickHandler from "@/app/components/global_click_handler"
import DefaultLayout from "@/app/default_layout"

let fpPromise
if (isBrowser()) {
  fpPromise = FingerprintJS.load()
}

export default function LoginPage() {
  const [pageData] = useAtom(pageDataAtom)
  // eslint-disable-next-line
  const [valid, setValid] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  // eslint-disable-next-line
  const [wsAllowed, setWsAllowed] = useAtom(wsAllowedAtom)

  setWsAllowed(false)
  useGlobalClickHandler(fpPromise)

  function emailChg(ev) {
    const v = ev.currentTarget.value.trim()

    if (v !== "") {
      setEmail(v)
      if (password != "") {
        setValid(() => true)
      }
    } else {
      setValid(() => false)
    }
  }

  function passwordChg(ev) {
    const v = ev.currentTarget.value.trim()
    // console.log(valid)

    if (v !== "") {
      setPassword(v)
      if (email != "") {
        setValid(() => true)
      }
    } else {
      setValid(() => false)
    }
  }

  function trackUsernameInput(ev) {
    if (pageData && pageData.highlightEnabled) {
      const username = ev.currentTarget.value.trim()
      H.track("USERNAME_INPUT", {
        username,
        datetime: (new Date())
      })
    }
  }

  function signIn(redirect) {
    const emailEl = document.getElementById('email')
    const passwordEl = document.getElementById('password')

    const _email = email === "" ? emailEl.value : email
    const _password = password === "" ? passwordEl.value : password


    fetch(`/accounts/sign_in`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email: _email, password: _password })
    })
      .then(res => {

        if (res.status === 200) {



          const search = new URLSearchParams(location.search)
          if(search.has('redirect')) {
            const _redirect = search.get("redirect")
            return location.href=_redirect
          }

          res.json().then(json => {

            localStorage.clear()
            localStorage.setItem('field_well_selector', JSON.stringify({ selectedFieldId: -1, selectedWellId: -1 }))
            if (Array.isArray(json.well_ids)) {
              localStorage.setItem('well_ids', JSON.stringify(json.well_ids))
            }

            if (Array.isArray(json.field_ids)) {
              localStorage.setItem('field_ids', JSON.stringify(json.field_ids))
            }

            localStorage.setItem('email', _email)

            if (redirect) {
              return location.href = redirect
            }

            if (json.res && Array.isArray(json.capabilities)) {
              location.href = "/fields"
            } else if (json.res) {
              location.href = "/projects"
            } else {
              setError("Invalid username or password.")
            }
          })
        }
      })
  }

  useEffect(() => {
    if (isBrowser() && pageData !== null && pageData.loggedIn) {
      if (Array.isArray(pageData.well_ids)) {
        localStorage.setItem('well_ids', JSON.stringify(pageData.well_ids))
      }

      if (Array.isArray(pageData.field_ids)) {
        localStorage.setItem('field_ids', JSON.stringify(pageData.field_ids))
      }

      if (pageData.capabilities) {
        location.href = "/fields"
      } else
        location.href = "/projects"
    }
  }, [pageData])

  return (
    <DefaultLayout>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {error === "" ? null : (
            <div className="mb-10 text-red-600">
              {error}
            </div>
          )
          }
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  onChange={emailChg}
                  id="email"
                  name="email"
                  type="email"
                  required
                  onBlur={trackUsernameInput}
                  onKeyUp={ev => {
                    if (ev.code === 'Enter') {
                      signIn(pageData?.redirect)
                    }
                  }}
                  autoComplete="email"
                  className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-950 sm:text-sm sm:leading-6 p-4"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <a href={"/accounts/password/new"}>Forgot password?</a>

                </div>
              </div>
              <div className="mt-2">
                <input
                  onChange={passwordChg}
                  id="password"
                  name="password"
                  type="password"
                  required
                  onKeyUp={ev => {
                    if (ev.code === 'Enter') {
                      signIn(pageData?.redirect)
                    }
                  }}
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-950 sm:text-sm sm:leading-6 p-4"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={() => signIn(pageData?.redirect)}
                onKeyUp={ev => {
                  if (ev.code === 'Enter') {
                    signIn(pageData?.redirect)
                  }
                }}
                className="flex w-full justify-center rounded-md bg-indigo-950 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lime-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-950"
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )

}