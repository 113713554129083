
"use client"

import "./globals.css";

import Image from "next/image";
import logo from "./images/evans-energy-logo.png"
// import Link from "next/link";
// import Head from 'next/head'
import ProfileNav from '@/app/components/profile_nav'
import isBrowser from "./helpers/is_browser";

// eslint-disable-next-line
import { useEffect, useRef, useCallback, useState } from "react";
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

import { pageDataAtom, hasNetworkAtom, modalOpenedAtom, isLeadsPageAtom, wsAllowedAtom, globalErrorAtom, profilePicAtom, highlightEnabledAtom } from "@/app/state"
import { useAtom } from "jotai";
import OfflineBanner from "./components/offline_banner";
import useWindowSize from "@/app/helpers/use_window_size"
import headers from "@/app/helpers/default_headers"


import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { PushNotifications } from '@capacitor/push-notifications';
import Fetch from "@/app/helpers/fetch_with_to"

const _fetch = Fetch(2500)


import FingerprintJS from '@fingerprintjs/fingerprintjs'

let fpPromise

if (isBrowser()) {
  fpPromise = FingerprintJS.load()
}


import { load } from '@fingerprintjs/botd'

import { XMarkIcon } from "@heroicons/react/20/solid";

/*
import {
  newTracker,
  enableActivityTracking,
  trackPageView
} from '@snowplow/browser-tracker';


if (isBrowser()) {

  const proto = location.protocol
  const url = `${proto}://${location.host}`
  newTracker('default', url, {
    appId: 'ee-portal',
  });

  enableActivityTracking({
    minimumVisitLength: 30,
    heartbeatDelay: 10
  });

  trackPageView()
}
*/




// import posthog from 'posthog-js'


const blacklistOfflineBanner = ['/login', '/invest']


// import InstallPrompt from "./components/install_prompt";
// import PushNotificationManager from "./components/push_notifications_manager";

// import type { Metadata } from "next";
// export const metadata: Metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };



function LeftProjectLogo({ project }) {

  if (!project) {
    return null
  }

  return (<>
    <div className="logo-forward" style={{ alignSelf: 'center', marginLeft: '-0.8rem' }}>
      <span className="material-symbols-outlined">arrow_forward</span>
    </div>
    <div className="flex items-center">
      <img alt="project images" src={project.icon} />
    </div>
  </>)
}

function DisplayName({ name }) {
  if (!name) {
    return null
  }
  return (
    <>{name}</>
  )

}

// eslint-disable-next-line
function AdminLogout({ loggedIn }) {
  if (isBrowser() && !loggedIn) {
    return null
  }
  return (
    <>
      <li style={{ margin: '0', paddingRight: '1rem' }} >
        {/* <Link style={{ color: "white" }} href={"/login"}>Home</Link> */}
        <a style={{ color: "white" }} href={"/login"}>Home</a>
      </li>
      <li style={{ margin: 0 }}>
        <a style={{ color: "white" }} href="/logout">Log Out</a>
        {/* <Link style={{ color: "white" }} href={"/admin/logout"}>Log Out</Link> */}
      </li>
    </>
  )
}

// eslint-disable-next-line
function UserLogout({ loggedIn }) {
  return (
    <>
      <li style={{ margin: '0', paddingRight: '1rem' }} >
        <a style={{ color: "white" }} href={"/login"}>Home</a>
        {/* <Link style={{ color: "white" }} href={"/login"}>Home</Link> */}
      </li>
      <li style={{ margin: 0 }}>
        <a style={{ color: "white" }} href="/logout">Log Out</a>
        {/* <Link style={{ color: "white" }} href={"/accounts/sign_out"}>Log Out</Link> */}
      </li>
    </>)

}

// eslint-disable-next-line
function AppInstallBanner() {
  let host = ""
  if (isBrowser()) {
    host = location.hostname
  }
  if (host !== "evans-energy-b9ba239f1ce7.herokuapp.com") {
    return null
  }

  return (
    <div className="w-full bg-red-600 text-white justify-center flex font-bold p-4">
      <a href="/assets/evans_energy2.apk">Install the app</a>
    </div>
  )
}

export default function DefaultLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {


  // eslint-disable-next-line
  const [pageData, setPageData] = useAtom(pageDataAtom)
  // eslint-disable-next-line
  const [modalOpened, setModalOpened] = useAtom(modalOpenedAtom)
  const [loggedIn, setLoggedIn] = useState(false)

  const [wellAdmin, setWellAdmin] = useState(false)
  const [wellUser, setWellUser] = useState(false)
  const [fullAdmin, setFullAdmin] = useState(false)

  const [project, setProject] = useState("")
  const [name, setName] = useState("")
  const [initComplete, setInitComplete] = useState(false)
  const [hasNetwork, setHasNetwork] = useAtom(hasNetworkAtom)

  // eslint-disable-next-line
  const [highlightEnabled, setHighlightEnabled] = useAtom(highlightEnabledAtom)
  const [menuItems, setMenuItems] = useState([])
  const [isBot, setIsBot] = useState(false)
  const [isLeadsPage] = useAtom(isLeadsPageAtom)
  const [globalError, setGlobalError] = useAtom(globalErrorAtom)
  const [profilePic, setProfilePic] = useAtom(profilePicAtom)

  // eslint-disable-next-line
  const [wsAllowed] = useAtom(wsAllowedAtom)


  // let lastHeartbeat = now()
  // const [lastHeartbeat, setLastHeartbeat] = useState((new Date().getTime()))


  // const workerRef = useRef<Worker>();

  // function now() {
  //   return (new Date().getTime())
  // }

  // useEffect(() => {
  //   workerRef.current = new Worker(new URL("./worker.ts", import.meta.url));
  //   workerRef.current.onmessage = (event: MessageEvent<number>) => {

  //     if(event.data === "close") {    
  //       setHasNetwork(false)
  //     } else if(event.data === "pong") {
  //       const _now = now()
  //       const delta = _now - lastHeartbeat
  //       lastHeartbeat = now()
  //       const dead = delta > 2000

  //       setHasNetwork(!dead)
  //     }
  //   }
  //   return () => {
  //     workerRef.current?.terminate();
  //   };
  // }, []);

  function setupBotCheck() {
    return load()
      .then((botd) => botd.detect())
      .then((result) => {
        console.log(result)
        if (result.bot) {
          setIsBot(() => true)
        }
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {

    if (profilePic === undefined) {
      fetch('/profile/get_pic.json')
        .then(res => res.json())
        .then(imgRes => {
          // debugger
          setProfilePic(imgRes.image)
        })

    }
  }, [profilePic])

  useEffect(() => {

    setupBotCheck()
    if (!initComplete && isBrowser() && window && window.pageData) {
      setPageData(window.pageData)

      // trackPageView()

      if (window.pageData.highlightEnabled) {

        const suf = window.pageData.is_admin ? "_impersonator" : ''

        if (H && window.pageData.email && isBot) {
          // eslint-disable-next-line
          H.identify(window.pageData.email + suf + '_IS_BOT_')
        } else if (H && window.pageData.email) {
          // eslint-disable-next-line
          H.identify(window.pageData.email + suf)
        } else if (isBot) {
          H.identify("IS_BOT")
        }
        setHighlightEnabled(() => true)
      }
    }
    // else if (!initComplete && isBrowser() && pageData === null) {
    // fetch('/audit.json')
    // fetch('/audit/favs.json')


    // fetch('/fields/well_tickets.json')
    // fetch('/fields/well_pictures.json')
    // fetch('/fields/well_checklist.json')
    // fetch('/fields/daily_totals.json')
    // fetch('/fields/well_files.json')
    // fetch('/projects/9.json')
    // fetch('/projects.json')
    // fetch('/projects/5.json')
    // fetch('/projects/all.json')
    // fetch('/profile/contact.json')
    // fetch('/profile/addresses.json')
    // fetch('/admin2/well_percentages.json')
    // fetch('/admin2/data_check')
    // .then(res => res.json())
    // .then(data => {
    //   if (data.project) {
    //     data.project.icon = ""
    //     data.projects = data.projects.map(project => {
    //       project.icon = ""
    //       return project
    //     })
    //   }
    //   setPageData(data)
    // })
    // }

    if (!initComplete && isBrowser() && pageData !== null) {
      fpPromise.then(async (fp) => {
        fp.get().then(result => {
          const email = localStorage.getItem('email') || ''
          fetch('/ev', {
            method: 'POST',
            headers,
            body: JSON.stringify({
              track: {
                client_time: new Date().toISOString(),
                event_type: 'page_view',
                email,
                fp: result.visitorId,
                properties: {
                  path: location.pathname,
                  query: location.search,
                  href: location.href
                }
              }
            })
          })
        })
      })
      setLoggedIn(() => pageData.loggedIn)
      setWellAdmin(() => pageData.wellAdmin)
      setWellUser(() => pageData.wellUser)
      setFullAdmin(() => pageData.fullAdmin)



      if(isBrowser() && Capacitor.isNative) {
        // eslint-disable-next-line
        const addListeners = async () => {
          await PushNotifications.addListener('registration', token => {
            // console.info('Registration token: ', token.value);
            fetch('/api/devices', { method: 'POST', headers, body: JSON.stringify({ token: token.value, platform: 'ios' }) })
          });
        
          // await PushNotifications.addListener('registrationError', err => {
          //   console.error('Registration error: ', err.error);
          // });
        
          // await PushNotifications.addListener('pushNotificationReceived', notification => {
          //   console.log('Push notification received: ', notification);
          // });
        
          await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            // console.log('Push notification action performed', notification.actionId, notification.inputValue);
            // console.log(notification)
            const url = notification?.notification?.data?.url
            if(url) {
              location.href = url
            }
          });
        }
        // eslint-disable-next-line
        const registerNotifications = async () => {
          await addListeners()
          await SplashScreen.hide()
          let permStatus = await PushNotifications.checkPermissions();

          console.log('permStatus ', permStatus)

          await PushNotifications.requestPermissions()
        
          if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
          }
        
          if (permStatus.receive !== 'granted') {

            return fpPromise.then(async (fp) => {
              fp.get().then(result => {
                const email = localStorage.getItem('email') || ''
                fetch('/ev', {
                  method: 'POST',
                  headers,
                  body: JSON.stringify({
                    track: {
                      client_time: new Date().toISOString(),
                      event_type: 'push_notification_denied',
                      email,
                      fp: result.visitorId,
                      properties: {
                        path: location.pathname,
                        query: location.search,
                        href: location.href
                      }
                    }
                  })
                })
              })
            })
          }
          await PushNotifications.register();
        }
        // eslint-disable-next-line
        // const getDeliveredNotifications = async () => {
        //   const notificationList = await PushNotifications.getDeliveredNotifications();
        //   console.log('delivered notifications', notificationList);
 
        // }
        registerNotifications()
      }

      const tmpMenuItems = []
      
      if (pageData.fullAdmin || pageData.auditUser || pageData.wellAdmin || pageData.wellUser) {
        tmpMenuItems.push({ name: 'Home', href: '/fields' })
        
      } else {
        tmpMenuItems.push({ name: 'Home', href: '/' })
      }

      tmpMenuItems.push({ name: 'Profile', href: '/profile' })

      setMenuItems(() => tmpMenuItems)

      if (pageData.loggedIn) {
        setName(() => pageData.name)
        setProject(() => pageData.project)
      }

      setInitComplete(() => true)
      if (pageData === null) {
        setPageData(window.pageData)
      }
    }

  }, [loggedIn, project, name, pageData, wellAdmin, wellUser, fullAdmin, hasNetwork, setHasNetwork, initComplete, setPageData])

  useEffect(() => {
    if (isBrowser()) {
      const heartbeatInt = setInterval(() => {
        fpPromise.then(async (fp) => {
          fp.get().then(result => {
            const print = result.visitorId
            // eslint-disable-next-line
            _fetch(`/api/ping?f=${print}`, (res) => {
              if (!hasNetwork) {
                setHasNetwork(() => true)
              }
              // eslint-disable-next-line  
            }, (e) => {
              if (hasNetwork) {
                setHasNetwork(() => false)
              }
            })
          })
        })
      }, 3500)

      return () => clearInterval(heartbeatInt)
    }
  }, [loggedIn, project, name, pageData, wellAdmin, wellUser, fullAdmin, hasNetwork, setHasNetwork, initComplete, setPageData])


  // eslint-disable-next-line
  async function checkSWVersion() {
    const version = await serwist.messageSW({ type: 'GET_VERSION' })
    console.log("VERSION ", version)
  }

  // eslint-disable-next-line
  function isAdmin() {
    return wellAdmin || wellUser || fullAdmin
  }

  // const homeHref = isAdmin() ? '/login' : '/'

  const [width] = useWindowSize()

  const [isMobile, setIsMObile] = useState(false)


  useEffect(() => {
    setIsMObile(width < 640)
  }, [width])


  // useEffect(() => {
  //   if (isBrowser()) {
  //     if(wsAllowed) {
  //       workerRef.current?.postMessage(JSON.stringify({command: 'ws_init'}))
  //     }
  //   }
  // }, [wsAllowed])



  return (
    <>


            {hasNetwork || (isBrowser() && (isLeadsPage || blacklistOfflineBanner.includes(location.pathname))) ? null :
              <OfflineBanner />
            }


            {isLeadsPage ? null : (
              <nav className="header border-gray-200">

                <div style={{ height: '100%' }}>
                  <div className="flex" style={{ height: '100%' }}>
                    <div className="flex" style={{ maxWidth: '355px', height: '100%', padding: '0 1rem 0 1rem' }}>
                      <a href="/projects" className="flex items-center space-x-3 rtl:space-x-reverse no-underline">
                        <Image alt="Evans Energy Logo" src={logo} style={{ width: '175px' }} />
                      </a>
                      <LeftProjectLogo project={project} />

                      {modalOpened || !loggedIn || !isMobile || !hasNetwork ? null : (
                        <div className="absolute" style={{ right: 0 }}>
                          <ProfileNav menuItems={menuItems} />
                        </div>
                      )}

                    </div>

                    <div className="flex header_account_name">

                      <div className="hidden md:block" style={{ margin: '0 auto', alignContent: 'center' }} >
                        <h1 className="project_name" >
                          <DisplayName name={name} />
                        </h1>
                      </div>

                      {modalOpened || !loggedIn || !hasNetwork ? null : (
                        <ProfileNav menuItems={menuItems} />
                      )}
                    </div>
                  </div>
                </div>
              </nav >
            )}

            {globalError === "" ? null : (
              <div
                aria-live="assertive"
                className="relative pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
              >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                  {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                  <Transition show={globalError !== ""}>
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                      <div className="p-4">
                        <div className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-red-400" />
                          </div>
                          <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">{globalError}</p>
                            <p className="mt-1 text-sm text-gray-500"></p>
                          </div>
                          <div className="ml-4 flex flex-shrink-0">
                            <button
                              type="button"
                              onClick={() => {
                                setGlobalError("")
                              }}
                              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lime-950 focus:ring-offset-2"
                            >
                              <span className="sr-only">Close</span>
                              <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            )}

            {/* <InstallPrompt /> */}
            {children}

    </>
  );
}
