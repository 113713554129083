import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import useWindowSize from "@/app/helpers/use_window_size"
import { useEffect, useState } from "react";
import { profilePicAtom, editProfilePicStateAtom } from "@/app/state";
import {useAtom} from 'jotai'
import EditProfilePic from "@/app/components/edit_profile_pic"

export default function ProfileNav({ menuItems }) {
  const [width] = useWindowSize()
  const [isMobile, setIsMObile] = useState(false)
  // eslint-disable-next-line
  const [profilePic, setProfilePic] = useAtom(profilePicAtom)
  const [bg, setBg] = useState('rgb(14,31,64)')
  const [fontColor, setFontColor] = useState('text-white')
  const [editProfilePicState] = useAtom(editProfilePicStateAtom)

  useEffect(() => {
    const _isMobile = width < 640
    setIsMObile(_isMobile)
    setBg(_isMobile ? 'rgb(255, 200, 36)' : 'rgb(14,31,64)')
    setFontColor(_isMobile ? 'pl-12 text-white': 'text-white')
  }, [width])

  return (
    <div>

      {editProfilePicState > 0 ? 

        <EditProfilePic />
        : null }
      <Popover className="relative isolate z-50">
      <div className="bg-white">
        <div style={{backgroundColor: bg}} className="text-white mx-auto max-w-7xl px-3 lg:px-4">
          <PopoverButton style={{border: 'none', boxShadow: 'none', height: '55px'}} className="popover-custom inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
            <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">

              {profilePic === undefined ? (
                <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              ) : (
                <img style={{width: "100%", height: "100%"}} src={profilePic} />
              )}
            </span>
          </PopoverButton>
        </div>
      </div>

      <PopoverPanel
        transition
        style={{marginLeft: !isMobile ? '-115px' : '', backgroundColor: bg}}
        className="absolute project-popover inset-x-0 top-0 -z-10 bg-white pt-16 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="mx-auto max-w-7xl ">
          {menuItems.map((item) => (
            
            <div
              key={item.name}
              className="group project-menu-item relative flex text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-3"
            >
              <div className={`flex h-22 w-22 pt-4 pb-4 md:pt-0 md:pb-4 flex-none items-center justify-center group-hover:bg-white ${fontColor} font-semibold group-hover:text-black`}>
                {item.name}
              </div>
              <div>
                
                  <a href={item.href} className="font-semibold text-gray-900">
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-gray-600">{item.description}</p>
                
              </div>
            </div>
          ))}


            <div
              className="group project-menu-item relative flex text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-3"
            >
              <div className={`flex h-22 w-22 pt-4 pb-4 md:pt-0 md:pb-4 flex-none items-center justify-center group-hover:bg-white ${fontColor} font-semibold group-hover:text-black`}>
                Logout
              </div>
              <div>
                  <a href="/logout" className="font-semibold text-gray-900">
                    <span className="absolute inset-0" />
                  </a>
              </div>
            </div>
          
        </div>  
      </PopoverPanel>
    </Popover>
    </div>
  )
}