import headers from "@/app/helpers/default_headers"

export default function FetchTo(ms) {
  return (path, successCb, errorCb) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), ms);

    return fetch(path, { headers, signal: controller.signal })
      .then(res => {
        if(res.status < 400 && typeof(successCb) === 'function') {
          return successCb(res)
        } else if(res.status > 399 && typeof(errorCb) === 'function') {
          return errorCb(e)
        }
      })
      .catch((e) => {
        if(typeof(errorCb) === 'function') {
          return errorCb(e)
        }
      })  
      .finally(() =>
        clearTimeout(timeoutId)
      )
  }
}